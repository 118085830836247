// Generated by Framer (f4b44ab)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/HmIE27HKr";

const enabledGestures = {U_JtbNT9k: {hover: true}};

const cycleOrder = ["U_JtbNT9k"];

const serializationHash = "framer-adTLR"

const variantClassNames = {U_JtbNT9k: "framer-v-ew7nhd"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title, width, ...props}) => { return {...props, nfbnB0drL: title ?? props.nfbnB0drL ?? "Item 1"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, nfbnB0drL, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "U_JtbNT9k", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-adTLR", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-ew7nhd", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"U_JtbNT9k"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"U_JtbNT9k-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-wmz6fp"} data-framer-name={"Content"} layoutDependency={layoutDependency} layoutId={"uzNWKexg8"} style={{backgroundColor: "rgba(0, 0, 0, 0)", borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4}} variants={{"U_JtbNT9k-hover": {backgroundColor: "var(--token-31c6d85c-0045-40a0-8547-5aa9974061e0, rgb(0, 148, 221))"}}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-ag3mnv"} data-styles-preset={"HmIE27HKr"}>Item 1</motion.p></React.Fragment>} className={"framer-1hmkurd"} layoutDependency={layoutDependency} layoutId={"WgbD4gc9u"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={nfbnB0drL} variants={{"U_JtbNT9k-hover": {"--extracted-r6o4lv": "var(--token-9cf97cd9-734b-4dee-ad16-48480ceaac19, rgb(255, 255, 255))"}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({"U_JtbNT9k-hover": {children: <React.Fragment><motion.p className={"framer-styles-preset-ag3mnv"} data-styles-preset={"HmIE27HKr"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-9cf97cd9-734b-4dee-ad16-48480ceaac19, rgb(255, 255, 255)))"}}>Item 1</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-adTLR [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-adTLR .framer-p5ffkj { display: block; }", ".framer-adTLR .framer-ew7nhd { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 2px 4px 2px 4px; position: relative; width: 215px; }", ".framer-adTLR .framer-wmz6fp { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: hidden; padding: 10px 12px 10px 12px; position: relative; width: 100%; will-change: var(--framer-will-change-override, transform); }", ".framer-adTLR .framer-1hmkurd { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-adTLR .framer-ew7nhd, .framer-adTLR .framer-wmz6fp { gap: 0px; } .framer-adTLR .framer-ew7nhd > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-adTLR .framer-ew7nhd > :first-child { margin-top: 0px; } .framer-adTLR .framer-ew7nhd > :last-child { margin-bottom: 0px; } .framer-adTLR .framer-wmz6fp > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-adTLR .framer-wmz6fp > :first-child { margin-left: 0px; } .framer-adTLR .framer-wmz6fp > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 48
 * @framerIntrinsicWidth 215
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"YGEpFsfKo":{"layout":["fixed","auto"]}}}
 * @framerVariables {"nfbnB0drL":"title"}
 * @framerImmutableVariables true
 */
const Framercl9Zzttzt: React.ComponentType<Props> = withCSS(Component, css, "framer-adTLR") as typeof Component;
export default Framercl9Zzttzt;

Framercl9Zzttzt.displayName = "Item";

Framercl9Zzttzt.defaultProps = {height: 48, width: 215};

addPropertyControls(Framercl9Zzttzt, {nfbnB0drL: {defaultValue: "Item 1", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(Framercl9Zzttzt, [...sharedStyle.fonts])